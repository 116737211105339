<template>
  <component is="b-card">
    <div>
      <!-- User Info: Input Fields -->
      <b-form>
        <b-row>
          <!-- Field: Client -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Client')" label-for="client">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="clients"
                label="name"
                input-id="client"
                v-model="clientValue"
                :loading="loading"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <b-card no-body class="mb-0">
        <b-table
          id="scrolle-table"
          ref="refOrderListTable"
          class="position-relative"
          :items="fetchOrders"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :empty-text="$t('No matching records found')"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              class="btn-icon"
              @click="consulteOrder(data.item)"
            >
              <feather-icon icon="EyeIcon" size="16" />
              <!--Consult-->
            </b-button>
            &nbsp;
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              class="btn-icon"
              @click="affectOrder(data.item)"
            >
              <feather-icon icon="PlusIcon" size="16" />
              <!--Affect-->
            </b-button>

            <b-modal
              :ref="'consulte-order-' + data.item.id"
              centered
              ok-only
              size="xl"
              @hidden="onConsulteOrderModalHidden"
              :title="$t('Consultation')"
              hide-footer
            >
              <div class="flex_box">
                <table style="width: max-content !important">
                  <tbody>
                    <tr>
                      <td class="color border padding_table">
                        <strong>{{ $t("Client") }}</strong>
                      </td>
                      <td class="border padding_table">{{ client }}</td>
                    </tr>
                    <tr class="border">
                      <td class="color border padding_table">
                        <strong>{{ $t("Order source") }}</strong>
                      </td>
                      <td class="border padding_table">{{ orderSource }}</td>
                    </tr>
                  </tbody>
                </table>
                <table style="width: max-content !important">
                  <tbody>
                    <tr>
                      <td class="color border padding_table">
                        <strong>{{ $t("Order date") }}</strong>
                      </td>
                      <td class="border padding_table">{{ orderDate }}</td>
                    </tr>
                    <tr class="border">
                      <td class="color border padding_table">
                        <strong>{{ $t("Order add by") }}</strong>
                      </td>
                      <td class="border padding_table">{{ operator }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <b-table
                id="scrolle-table"
                show-empty
                :empty-text="$t('No matching records found')"
                :items="orderLines"
                :fields="fields"
                responsive
                @row-selected="onRowSelected"
              >
                <template #cell(state)="data">
                  <!-- <custom-order-line-state-badge :state="data.item.state" /> -->
                  {{ data.item.state.name }}
                </template>
              </b-table>
            </b-modal>
          </template>
        </b-table>
        <b-modal
          ref="orderline-prepare-line"
          cancel-variant="outline-primary"
          :cancel-title="$t('Cancel')"
          :ok-title="$t('Affect')"
          centered
          size="xl"
          :title="$t('Alert')"
          @ok="confirmAffectedOrderLine"
        >
          <b-table
            id="scrolle-table"
            show-empty
            :empty-text="$t('No matching records found')"
            selectable
            select-mode="multi"
            :items="orderLinesAffected"
            :fields="columns"
            responsive
            @row-selected="onRowSelected"
          >
            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected">
                <i class="feather icon-disc primary" />
              </template>

              <template v-else>
                <i class="feather icon-circle" />
              </template>
            </template>
          </b-table>
        </b-modal>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{
                $t("Pagination text", [dataMeta.from, dataMeta.to, dataMeta.of])
              }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalOrders"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BAlert,
  BLink,
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BPagination,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BCardText,
  BBadge,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import instance from "@/libs/axios";
import useAffectingOrderList from "./useAffectingOrderList";
import Ripple from "vue-ripple-directive";
import CustomOrderLineStateBadge from "@/views/ui/CustomOrderLineStateBadge.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    CustomOrderLineStateBadge,
    vSelect,
    flatPickr,
    BTab,
    BTabs,
    BAlert,
    BLink,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BPagination,
    BDropdown,
    BDropdownItem,
    BModal,
    VBModal,
    BCardText,
    BBadge,
  },

  async mounted() {
    try {
      this.loading = true;
      const res = await Promise.all([
        instance.get("/parameters/clients/"),
        //this.fetchDepartReturnState(),
      ]);
      this.clients = res[0].data;
    } catch (err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Error"),
          text: err.message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }finally {
      this.loading = false;
    }
  },
  data() {
    return {
      loading: null,
      columns: [
        { key: "selected", label: this.$t("selected") },
        { key: "client_annexe", label: this.$t("client annexe") },
        { key: "product", label: this.$t("product") },
        { key: "available_qty", label: this.$t("available qty") },
        { key: "available_piece", label: this.$t("available piece") },
        { key: "shipping_type", label: this.$t("shipping type") },
        { key: "shipping_date", label: this.$t("shipping date") },
        { key: "emergency_degree", label: this.$t("emergency degree") },
      ],
      fields: [
        { key: "client_annexe", label: this.$t("client annexe") },
        { key: "product", label: this.$t("product") },
        { key: "available_qty", label: this.$t("available qty") },
        { key: "available_piece", label: this.$t("available piece") },
        { key: "shipping_type", label: this.$t("shipping type") },
        { key: "shipping_date", label: this.$t("shipping date") },
        { key: "emergency_degree", label: this.$t("emergency degree") },
      ],
      orderDate: null,
      client: null,
      orderSource: null,
      shippingType: null,
      shippingDate: null,
      operator: null,
      clients: [],
      orderLines: [],
      selectedOrderLines: [],
      selectedClient: null,
      timeoutId: null,
      order: null,
      order_lignes: [],
      selectedOrderLine: [],
      selectedChangeOrderLine: [],
      orderLinesAffected: [],
    };
  },
  methods: {
    onRowSelected(orderLine) {
      this.selectedOrderLines = orderLine;
      this.selectedChangeOrderLine = [];
      this.selectedOrderLines.forEach((orderLine) => {
        this.selectedChangeOrderLine.push(orderLine.id);
      });
    },
    async affectOrder(order) {
      const result = await this.$bvModal.msgBoxConfirm(
        this.$t("Please choose the suitable action for you."),
        {
          title: "Alert",
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t("All lines"),
          cancelTitle: this.$t("By line"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (result === null || result === undefined) {
        return;
      }

      try {
        await this.openCancelingOrderReasonByLineModel(order);
        if (result) {
          const resutls = await this.$bvModal.msgBoxConfirm(
            this.$t("Are you sure you want to affect this order?"),
            {
              title: "Alert",
              size: "sm",
              okVariant: "primary",
              okTitle: this.$t("Yes"),
              cancelTitle: this.$t("No"),
              cancelVariant: "outline-secondary",
              hideHeaderClose: false,
              centered: true,
            }
          );

          if (!resutls) {
            return;
          }
          await instance.post(`/orders/delivery/affected/`, {
            order_id: order.id,
          });
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$t("Success"),
              text: this.$t("Success"),
              icon: "CheckIcon",
              variant: "success",
              noAutoHide: true,
            },
          });
          this.refetchData();
        } else {
          this.$refs[`orderline-prepare-line`].show();
        }
      } catch (err) {
        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: error ?? this.$t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async openCancelingOrderReasonByLineModel(order) {
      this.orderLinesAffected = [];
      const res = await instance.get(
        `/orders/${order.id}/order-lines/delivery/`,
        {
          params: {
            state_id: "received",
            role: "delivery",
            dock: this.dockValue?.id,
          },
        }
      );
      res.data.results.forEach((orderLine) => {
        const {
          product_shortcut
        } = orderLine.product_combination;

        const {
          state,
          ordered_qty,
          piece,
          available_qty,
          available_piece,
          shipping_type,
          shipping_date,
          emergency_degree,
          client_annexe,
        } = orderLine;
          if(emergency_degree!=null){
            var emergency_degree_name = emergency_degree.name;
          }else{
            var emergency_degree_name = null;
          }
          if(client_annexe!=null){
            var client_annexe_name = client_annexe.name;
          }else{
            var client_annexe_name = null;
          }
        this.orderLinesAffected.push({
          id: orderLine.id,
          product:product_shortcut,
          state: state.name,
          ordered_qty: ordered_qty,
          available_qty: available_qty,
          piece: piece,
          available_piece: available_piece,
          shipping_type: shipping_type.name,
          shipping_date: shipping_date,
          emergency_degree: emergency_degree_name,
          client_annexe : client_annexe_name,
          selected: true,
          orderSource:null,
        });
      });
    },

    async confirmAffectedOrderLine(bvModalEvt) {
      try {
        const resutls = await this.$bvModal.msgBoxConfirm(
          this.$t("Are you sure you want to affect this order?"),
          {
            title: "Alert",
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("No"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        );

        if (!resutls) {
          return;
        }
        const data = this.selectedChangeOrderLine;
        await instance.post(`/orders/delivery/affected/orderlines/`, {
          orderlines: data,
        });

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Success"),
            text: this.$t("order lines have been affected"),
            icon: "CheckIcon",
            variant: "success",
          },
        });

        this.refetchData();
        this.selectedChangeOrderLine = [];
        this.$refs[`orderline-prepare-line`].hide();
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async consulteOrder(order) {
      this.orderDate = order.date;
      this.client = order.client.name;
      if (order.source != null) {
        this.orderSource = order.source.name;
      }
      if (order.operator != null) {
        this.operator = order.operator.name;
      }
      try {
        this.orderLines = [];
        this.order = order;

        const res = await instance.get(
          `/orders/${order.id}/order-lines/delivery/`,
          {
            params: {
              state_id: "received",
              role: "delivery",
              dock: this.dockValue?.id,
            },
          }
        );
        res.data.results.forEach((orderLine) => {
          const {
            product_shortcut,
          } = orderLine.product_combination;
          const {
            state,
            ordered_qty,
            piece,
            available_qty,
            available_piece,
            shipping_type,
            shipping_date,
            emergency_degree,
            client_annexe,
          } = orderLine;
          if(emergency_degree!=null){
            var emergency_degree_name = emergency_degree.name;
          }else{
            var emergency_degree_name = null;
          }
          if(client_annexe!=null){
            var client_annexe_name = client_annexe.name;
          }else{
            var client_annexe_name = null;
          }
          this.orderLines.push({
            id: orderLine.id,
            product:product_shortcut,
            state: state,
            ordered_qty: ordered_qty,
            available_qty: available_qty,
            piece: piece,
            available_piece: available_piece,
            shipping_type: shipping_type.name,
            shipping_date: shipping_date,
            ref: order.ref,
            emergency_degree: emergency_degree_name,
            client_annexe:client_annexe_name,
          });
        });

        this.$refs[`consulte-order-${order.id}`].show();
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async onConsulteOrderModalHidden() {
      this.refetchData();
      this.orderLines = [];
      //await this.fetchDepartReturnState();
    },
    fetchClientOptions(search, loading) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        try {
          loading(true);
          const res = await instance.get("/parameters/clients/", {
            params: { search },
          });
          this.clientsRes = res.data.results;
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: err.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          loading(false);
        }
      }, 500);
    },
  },

  setup(props) {
    const {
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      refValue,
      sortBy,
      isSortDirDesc,
      refOrderListTable,
      refetchData,
      subProfileValue,
      clientValue,
      shippingDateValue,
      emergencyDegreeValue,
      dockValue,
    } = useAffectingOrderList();

    return {
      // Sidebar
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      refValue,
      sortBy,
      isSortDirDesc,
      refOrderListTable,
      refetchData,
      subProfileValue,
      clientValue,
      shippingDateValue,
      emergencyDegreeValue,
      dockValue,
    };
  },
};
</script>

<style>
#baniere_gauche {
  height: 100%;
  float: left;
  top: 0;
  left: 0;
}

#baniere_droite {
  position: relative;
  height: 100%;
  float: right;
  top: 0;
  right: 0;
}
td.color {
  background-color: #f3f2f7;
}
table#scrolle-table {
  white-space: nowrap;
}
</style>
